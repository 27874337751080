import React from 'react'

import Block from '../waypoint-block'

const SectionHeader = ({
  heading,
  lede,
  subheading,
  children,
  classes,
  isH1 = false,
}) => {
  return (
    <Block>
      <div
        className={`t-section-heading ${
          typeof classes === 'undefined' ? '' : classes
        }`}
      >
        {typeof heading !== 'undefined' && <p>{heading}</p>}
        {isH1 && <h1 className="h2">{lede}</h1>}
        {!isH1 ? (
          <h2>
            {lede}
            {typeof subheading !== 'undefined' && (
              <React.Fragment>
                <br />
                <span className="color-secondary">{subheading}</span>
              </React.Fragment>
            )}
          </h2>
        ) : (
          <>
            {typeof subheading !== 'undefined' && (
              <h2>
                <span className="color-secondary">{subheading}</span>
              </h2>
            )}
          </>
        )}
        {children}
      </div>
    </Block>
  )
}

export default SectionHeader
