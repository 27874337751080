import React from 'react'

import SectionHeader from '../section-header'
import Block from '../waypoint-block'

const ClientStory = ({ client }) => {
  return (
    <Block>
      <div className="c-interview" id={client.data.company.toLowerCase()}>
        <section className="s-container">
          <SectionHeader
            lede={client.data.tagline}
            subheading={client.data.lede}
          />
          <picture>
            <source
              sizes="100vw"
              srcSet={`${client.data.image1.thumbnails.small_1x.url} 690w,
                ${client.data.image1.thumbnails.medium_1x.url} 1260w,
                ${client.data.image1.thumbnails.small_2x.url} 1380w,
                ${client.data.image1.thumbnails.medium_2x.url} 2520w,
                ${client.data.image1.thumbnails.large_1x.url} 2130w,
                ${client.data.image1.thumbnails.large_2x.url} 4260w,`}
            />
            <img src={client.data.image1.url} alt="" />
          </picture>
          {client.data.body1.raw.map((para, index) => (
            <p key={index} className="optimal-width optimal-width--center">
              {para.text}
            </p>
          ))}
          <blockquote className="optimal-width optimal-width--smaller optimal-width--center">
            <span>{client.data.pullquote1}</span>
          </blockquote>
          {client.data.body2.raw.map((para, index) => (
            <p key={index} className="optimal-width optimal-width--center">
              {para.text}
            </p>
          ))}
          {client.data.image2.url && (
            <picture>
              <source
                sizes="100vw"
                srcSet={`${client.data.image2.thumbnails.small_1x.url} 690w,
                  ${client.data.image2.thumbnails.medium_1x.url} 1260w,
                  ${client.data.image2.thumbnails.small_2x.url} 1380w,
                  ${client.data.image2.thumbnails.medium_2x.url} 2520w,
                  ${client.data.image2.thumbnails.large_1x.url} 2130w,
                  ${client.data.image2.thumbnails.large_2x.url} 4260w,`}
              />
              <img src={client.data.image2.url} alt="" />
            </picture>
          )}
          <div>
            {client.data.body3.raw[0].text &&
              client.data.body3.raw.map((para, index) => (
                <p key={index} className="optimal-width optimal-width--center">
                  {para.text}
                </p>
              ))}
          </div>
          {client.data.image3.url && (
            <picture>
              <source
                sizes="100vw"
                srcSet={`${client.data.image3.thumbnails.small_1x.url} 690w,
                  ${client.data.image3.thumbnails.medium_1x.url} 1260w,
                  ${client.data.image3.thumbnails.small_2x.url} 1380w,
                  ${client.data.image3.thumbnails.medium_2x.url} 2520w,
                  ${client.data.image3.thumbnails.large_1x.url} 2130w,
                  ${client.data.image3.thumbnails.large_2x.url} 4260w,`}
              />
              <img src={client.data.image3.url} alt="" />
            </picture>
          )}
          <blockquote className="optimal-width optimal-width--smaller optimal-width--center">
            <span>{client.data.pullquote2}</span>
          </blockquote>
          {client.data.body4.raw.map((para, index) => (
            <p key={index} className="optimal-width optimal-width--center">
              {para.text}
            </p>
          ))}
        </section>
      </div>
    </Block>
  )
}

export default ClientStory
