import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Scrollspy from 'react-scrollspy'

import Layout from '../components/layout'
import Prefooter from '../components/prefooter'
import PageHeader from '../components/page-header'
import ClientStory from '../components/client-story'
import ColorSwitch from '../components/waypoint-color-switch-children'
import Drift from '../components/waypoint-drift'

function ClientStoriesPage({ data }) {
  const clients = data.allPrismicInterviews.edges.map(item => item.node)
  return (
    <React.Fragment>
      <Helmet
        title="Versett – Client Stories"
        meta={[
          {
            name: 'description',
            content:
              'We partner with smart people working on solving interesting problems. We interviewed a handful of our clients about their work.',
          },
          {
            name: 'keywords',
            content: 'versett, client stories, client, stories, story',
          },
          { property: 'og:title', content: 'Versett – Client Stories' },
          {
            property: 'og:description',
            content:
              'We partner with smart people working on solving interesting problems. We interviewed a handful of our clients about their work.',
          },
          {
            property: 'og:image',
            content: 'https://versett.com/images/social-client-stories.jpg',
          },
          {
            property: 'og:url',
            content: 'https://versett.com/client-stories/',
          },
          { name: 'twitter:card', content: 'summary_large_image' },
          { property: 'og:site_name', content: 'Versett' },
          { name: 'twitter:image:alt', content: 'Client Stories' },
        ]}
      >
        <html lang="en" />
      </Helmet>
      <Layout>
        <section className="s-container s-page__hero s-page__hero--client-stories">
          <PageHeader
            lede="Client Stories."
            subheading="We partner with smart people working on solving interesting problems. We interviewed a handful of our clients about their work."
          />
        </section>
        <Drift />
        <Scrollspy
          items={clients.map(client => client.data.company.toLowerCase())}
          currentClassName="is-current"
          offset={-20}
          componentTag={'div'}
          className="c-filter c-filter--client-stories"
        >
          {clients.map((client, index) => (
            <a key={index} href={`#${client.data.company.toLowerCase()}`}>
              {client.data.company}
            </a>
          ))}
        </Scrollspy>
        <div className="s-client-stories">
          {clients.map((client, index) => {
            if (index % 2 === 0) {
              return <ClientStory key={index} client={client} />
            } else {
              return (
                <ColorSwitch key={index}>
                  <div className="color-switch__child">
                    <ClientStory client={client} />
                  </div>
                </ColorSwitch>
              )
            }
          })}
        </div>
        <Prefooter />
      </Layout>
    </React.Fragment>
  )
}

export const query = graphql`
  query clientStoriesQueries($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allPrismicInterviews(sort: { fields: [data___weight], order: ASC }) {
      edges {
        node {
          id
          data {
            company
            tagline
            lede
            image1 {
              url
              thumbnails {
                small_1x {
                  url
                }
                small_2x {
                  url
                }
                medium_1x {
                  url
                }
                medium_2x {
                  url
                }
                large_1x {
                  url
                }
                large_2x {
                  url
                }
              }
            }
            image2 {
              url
              thumbnails {
                small_1x {
                  url
                }
                small_2x {
                  url
                }
                medium_1x {
                  url
                }
                medium_2x {
                  url
                }
                large_1x {
                  url
                }
                large_2x {
                  url
                }
              }
            }
            image3 {
              url
              thumbnails {
                small_1x {
                  url
                }
                small_2x {
                  url
                }
                medium_1x {
                  url
                }
                medium_2x {
                  url
                }
                large_1x {
                  url
                }
                large_2x {
                  url
                }
              }
            }
            body1 {
              raw
            }
            body2 {
              raw
            }
            body3 {
              raw
            }
            body4 {
              raw
            }
            pullquote1
            pullquote2
          }
        }
      }
    }
  }
`

export default ClientStoriesPage
